import React, { Component } from "react";

class About extends Component {
  render() {
    if (this.props.data) {
      var profilepic = "images/" + this.props.data.image;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src={profilepic} alt="Jimmy Deng" />
          </div>
          <div className="nine columns main-col">
            <h2>
              Hi there, how are you doing? <br /> Oh me? I'm doing alright
            </h2>

            <p>
              I don't believe we've met - I'm Jimmy. I'm a software developer
              currently working on DevOps for embedded real-time software.
            </p>

            <p>
              I very much consider myself a maker at heart. I love making
              things, whether it be through coding a piece of software,
              designing and building electrical circuits, or even using computer
              aided design, 3D printing, and machine shop equipment to create
              functional mechanical parts.
            </p>

            <p>
              Unlike many other software developers, web development
              is neither my forté nor even remotely my interest. My passion
              consists of anything involving the interaction between software
              and hardware. This includes robotics, embedded systems, and
              real-time systems.
            </p>

            <p>
              Some of my other interests include baking, cooking, photography,
              gaming, computer vision, security, and reverse engineering.
            </p>

            <div className="row">
              <div className="columns contact-details">
                <h2>Want to get in touch?</h2>
                <p>
                  Feel free to send me an email at{" "}
                  <a className="email" href={"mailto:" + email}>
                    {email}
                  </a>
                </p>
              </div>
              {resumeDownload && (
                <div className="columns download">
                  <p>
                    <a href={resumeDownload} className="button">
                      <i className="fa fa-download"></i>Download Resumé
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
