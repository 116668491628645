import React, { Component } from "react";
import Modal from "react-modal";

class ProjectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  render() {
    const projects = this.props.data;
    const projectImage = "images/portfolio/" + projects.image;

    const toggleModal = () => {
      this.setState({ modalOpen: !this.state.modalOpen });
      // prevent scrolling on the main page when a modal is open
      if (this.state.modalOpen) {
        document.body.style.overflow = "unset";
      } else {
        document.body.style.overflow = "hidden";
      }
    };

    // const onClickAction = toggleModal;
    const onClickAction = null;

    if (projects.textBody.length > 0) {
      var projectTextBody = projects.textBody.map(function (paragraph) {
        return <p>{paragraph}</p>;
      });
    } else {
      projectTextBody = null;
    }

    if (projects.tools.length > 0) {
      var tools = projects.tools.map(function (tool) {
        return <li>{tool}</li>;
      });
    } else {
      tools = null;
    }

    if (projects.slideshow.length > 0 && projects.imgDir !== "") {
      var slideshow = projects.slideshow.map(function (imageName) {
        return (
          <img
            alt={projects.title}
            src={"images/" + projects.imgDir + "/" + imageName}
          />
        );
      });
    } else {
      slideshow = null;
    }

    return (
      <>
        <Modal
          className="projectModal"
          isOpen={this.state.modalOpen}
          onRequestClose={toggleModal}
        >
          <div className="projectModalHeader">
            <h2>
              <span className="projectModalHeaderOrangeText">&gt;&gt;</span>{" "}
              {projects.title}
            </h2>
            <div className="projectModalExitButtonDiv">
              <button
                className="projectModalExitButton"
                type="button"
                onClick={toggleModal}
              >
                &times;
              </button>
            </div>
          </div>
          <div className="projectModalBody">
            {projectTextBody != null ? (
              <>
                <div className="projectModalBodyMainContent">
                  {projectTextBody[0]}
                  <div className="projectModalBodyMainContentImg">
                    <img alt={projects.title} src={projectImage} />
                  </div>

                  {projectTextBody.length > 1 && projectTextBody.slice(1)}
                </div>
              </>
            ) : (
              <div className="projectModalBodyMainContent">
                <div className="projectModalBodyMainContentImg">
                  <img alt={projects.title} src={projectImage} />
                </div>
              </div>
            )}

            {tools != null && (
              <>
                <h3>Tools/Technologies Used</h3>
                <ul>{tools}</ul>
              </>
            )}

            {slideshow != null && (
              <>
                <h3>Gallery</h3>
                <div className="projectModalBodySlideshow">{slideshow}</div>
              </>
            )}
          </div>

          <div className="projectModalFooter">
            <button
              className="projectModalInfoButton"
              type="button"
              onClick={toggleModal}
            >
              <a href={projects.url}>Project Page</a>
            </button>
            <button
              className="projectModalExitButton"
              type="button"
              onClick={toggleModal}
            >
              &times;
            </button>
          </div>
        </Modal>
        <div key={projects.title} className="columns portfolio-item">
          <div className="item-wrap">
            <a href="#/" title={projects.title} onClick={onClickAction} >
              <img alt={projects.title} src={projectImage} />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5>{projects.title}</h5>
                  <p>{projects.brief}</p>
                </div>
              </div>
              <div className="link-icon">
                <i className="fa fa-link"></i>
              </div>
            </a>
          </div>
        </div>
      </>
    );
  }
}

class Portfolio extends Component {
  render() {
    if (this.props.data) {
      var projects = this.props.data.projects.map(function (projects) {
        return <ProjectModal data={projects} />;
      });
    }

    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>
              Some of my <strong>projects</strong>
            </h1>

            <h1>
              <strong>
                Please note that this section is still a work in progress.
              </strong>{" "}
              <br />
              <strong>More projects (and project details) to come!</strong>
            </h1>

            <div
              id="portfolio-wrapper"
              className="bgrid-thirds s-bgrid-halves cf"
            >
              {projects}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
