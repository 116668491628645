import React, { Component } from "react";

class ErrorPage extends Component {
    render() {
        require("./ErrorPage.css");
        return (
            <>
                <div id="main">
                    <div class="fof">
                        <h1>Error 404:</h1><br/>
                        <h2>Page not found</h2>
                    </div>
                </div>
            </>
        );
    }
}

export default ErrorPage;
