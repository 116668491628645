import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import App from "./App";
import ErrorPage from "./Components/ErrorPage";

class AppRouter extends Component {
  render() {
    return (
      <>
        <Routes>
          <Route path='*' element={<ErrorPage />}></Route>
          <Route path='/' element={<App />}></Route>
        </Routes>
      </>
    );
  }
}

export default AppRouter;
