import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from "react-ga4";
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import AppRouter from "./AppRouter";
import registerServiceWorker from './registerServiceWorker';

ReactGA.initialize("G-ZFBKLRVLCS");

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppRouter />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
registerServiceWorker();
